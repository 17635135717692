import Vue from 'vue'
import Vuex from 'vuex'
import { account } from './account.module.js'
import { alert } from './alert.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    photoUrl: null,
    canvasData: null,
    photoUid: null,
    signatureUid: null,
    shortlive_api_key: null,
    orderId: null,
    priceId: null,
    amount: null,
    stripePriceId: null,
    currency: null,
    checkoutSessionId: null,
    photoRejectReasons: null,
    rejectStatus: null,
    documentId: null,
    payByUser: true,
  },
  mutations: {
    savePhotoUrl(state, photoUrl) {
      state.photoUrl = photoUrl
    },
    canvasData(state, canvasData) {
      state.canvasData = canvasData
    },
    photoUid(state, photoUid) {
      state.photoUid = photoUid
    },
    signatureUid(state, signatureUid) {
      state.signatureUid = signatureUid
    },
    shortliveApiKey(state, shortliveApiKey) {
      state.shortliveApiKey = shortliveApiKey
    },
    orderId(state, orderId) {
      state.orderId = orderId
    },
    priceId(state, priceId) {
      state.priceId = priceId
    },
    amount(state, amount) {
      state.amount = amount
    },
    stripePriceId(state, stripePriceId) {
      state.stripePriceId = stripePriceId
    },
    currency(state, currency) {
      state.currency = currency
    },
    checkoutSessionId(state, checkoutSessionId) {
      state.checkoutSessionId = checkoutSessionId
    },
    photoRejectReasons(state, photoRejectReasons) {
      state.photoRejectReasons = photoRejectReasons
    },
    rejectStatus(state, rejectStatus) {
      state.rejectStatus = rejectStatus
    },
    documentId(state, documentId) {
      state.documentId = documentId
    },
    payByUser(state, payByUser) {
      state.payByUser = payByUser
    },
  },
  actions: {
    signatureFinished(context, { canvasData }) {
      context.commit('canvasData', canvasData)
    },
  },
  modules: {
    account,
    alert,
  },
})
