<template>
  <!--<div>
    <div :rail="rail" permanent @click="rail = false" class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <v-main>-->
  <v-sheet>
    <!--<v-img :src="icon" :width="iconWidth" class="mx-auto mb-5"></v-img>
          <h1 class="text-center" :style="styleTitle">{{ agenceName }}</h1>-->

    <h2
      class="text-center mb-15"
      :style="{
        styleTitle,
        fontFamily: 'Georama',
        fontSize: 24,
        fontWeight: 700,
        marginTop: '50px',
      }"
    >
      Conseils pour réussir <br />votre photo
    </h2>
    <v-row align="center" justify="center" class="mb-10 mt-13">
      <v-col cols="auto">
        <v-card
          width="350"
          height="82"
          style="margin-bottom: 30px"
          :color="cardColor"
          :variant="variant"
          class="rounded-xl"
        >
          <v-row align="center" class="pa-3">
            <v-col cols="auto">
              <v-img
                width="34"
                height="34"
                src="../../assets/images/icon_face2.png"
                alt=""
                :color="iconColor"
              />
            </v-col>
            <v-col cols="auto">
              <v-card-item>
                <div
                  class=""
                  style="
                    font-weight: 400;
                    font-size: 16px;
                    font-family: Georama;
                    color: #313b82;
                  "
                >
                  Une expression neutre
                </div>
              </v-card-item>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          width="350"
          height="82"
          style="margin-bottom: 30px"
          :color="cardColor"
          :variant="variant"
          class="rounded-xl"
        >
          <v-row align="center" class="pa-3">
            <v-col cols="auto">
              <v-img
                width="41.52"
                height="28"
                src="../../assets/images/icon_face1.png"
                alt=""
                :background-color="iconColor"
              />
            </v-col>
            <v-col cols="auto">
              <v-card-item>
                <div
                  class=""
                  style="
                    font-weight: 400;
                    font-size: 16px;
                    font-family: Georama;
                  "
                >
                  Le regard droit, vers l'objectif
                </div>
              </v-card-item>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          width="350"
          height="82"
          style="margin-bottom: 30px"
          :color="cardColor"
          :variant="variant"
          class="rounded-xl"
        >
          <v-row align="center" class="pa-3">
            <v-col cols="auto">
              <v-img
                width="36"
                height="36"
                src="../../assets/images/icon_sun1.png"
                alt=""
                :color="iconColor"
              />
            </v-col>
            <v-col cols="auto">
              <v-card-item>
                <div
                  style="
                    font-weight: 400;
                    font-size: 16px;
                    font-family: Georama;
                  "
                >
                  Le visage uniformément éclairé
                </div>
              </v-card-item>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          width="350"
          height="82"
          :color="cardColor"
          :variant="variant"
          class="rounded-xl"
        >
          <v-row align="center" class="pa-3">
            <v-col cols="auto">
              <v-img
                width="52"
                height="19.9"
                src="../../assets/images/icon_glasses1.png"
                alt=""
                :color="buttonValidColor"
              />
            </v-col>
            <v-col cols="auto">
              <v-card-item>
                <div
                  :color="buttonValidColor"
                  style="
                    font-weight: 400;
                    font-size: 16px;
                    font-family: Georama;
                  "
                >
                  Les lunettes retirées
                </div>
              </v-card-item>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-sheet class="mx-auto">
      <div class="d-flex flex-column">
        <v-btn
          class="rounded-pill mb-5 mt-5"
          width="350"
          height="52px"
          @click="startPhotoShoot"
          :color="buttonValidColor"
          :style="{
            color: buttonTextValidColor,
            fontWeight: 600,
            fontSize: 16,
            fontFamily: 'Georama',
            textTransform: 'none',
          }"
          >Continuer
        </v-btn>
      </div>
    </v-sheet>-->
  </v-sheet>
  <!-- </v-main>
  </div>-->
</template>
<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
//import Accueil from './Accueil.vue'

export default {
  name: 'Payment',
  /*components: {
    Accueil,
  },*/
  data() {
    return {
      confEmail: '',
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      errors: [],
      agenceName: '',
      externalId: '',

      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      iconColor: localStorageService.getStyle(location.hostname, 'iconColor'),
      cardColor: localStorageService.getStyle(location.hostname, 'cardColor'),

      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      isClicked: false,
    }
  },
  methods: {
    goBackAction() {
      this.$router.back()
      document.activeElement.blur()
    },
    startPhotoShoot() {
      this.$router.push({
        //name: 'TreatmentPhoto',
        name: 'ShootPhoto',
      })
    },
  },
  mounted() {
    const uuid = localStorageService.getValue('partner_uid')
    if (
      uuid !== undefined &&
      uuid !== null &&
      !localStorageService.getValue('logo')
    ) {
      requestService.get('/partner/uuid/' + uuid).then(response => {
        if (response.data.success) {
          this.agenceName = response.data.name
          if (response.data.logo !== null) this.icon = response.data.logo
          localStorageService.setObject(
            'logo',
            response.data.logo !== null ? response.data.logo : undefined,
          )
          localStorageService.setObject(
            'primaryColor',
            response.data.primaryColor !== null
              ? response.data.primaryColor
              : undefined,
          )
          localStorageService.setObject(
            'secondaryColor',
            response.data.secondaryColor !== null
              ? response.data.secondaryColor
              : undefined,
          )
        }
      })
    }
  },
}
</script>
<style>
.rounded-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 0px;
  background-color: #ffffff;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.photo-icon {
  margin: auto;
  width: 17px;
  height: 12px;
}
.text-align-left {
  display: inline-block;
  vertical-align: middle;
}
.btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
