<template>
  <div>
    <div v-show="!isCameraOpen">
      <TipsPhoto></TipsPhoto>
    </div>
    <div v-if="!isCameraOpen" class="justify-center">
      <v-sheet class="mx-auto">
        <div class="d-flex flex-column">
          <v-btn
            class="rounded-pill mb-5 mt-5"
            width="350"
            height="52px"
            @click="toggleCamera"
            :color="buttonValidColor"
            :style="{
              color: buttonTextValidColor,
              fontWeight: 600,
              fontSize: 16,
              fontFamily: 'Georama',
              textTransform: 'none',
            }"
            >Continuer
          </v-btn>
        </div>
      </v-sheet>
    </div>
    <div
      v-if="isCameraOpen && !isLoading && isPhotoTaken"
      class="camera-shoot justify-center"
    >
      <v-btn
        @click="takePhoto"
        :color="buttonNeutralColor"
        class="rounded-pill mb-5 mt-5"
        width="325"
        height="52px"
        :style="{
          fontWeight: 600,
          fontSize: 16,
          fontFamily: 'Georama',
          textTransform: 'none',
        }"
      >
        Reprendre la photo
      </v-btn>
    </div>

    <div
      v-show="isCameraOpen && isLoading"
      class="text-center align-middle"
      style="height: 300px"
    >
      <div class="text-caption" align="start">
        Veuillez accepter les autorisations d'utiliser votre caméra.
      </div>
      <br />
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
      <br />
      <span>Veuillez patienter</span>
    </div>

    <div
      v-if="isCameraOpen"
      v-show="!isLoading"
      class="camera-box d-flex justify-center overflow-hidden"
      :class="{ flash: isShotPhoto }"
    >
      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

      <video
        v-show="!isPhotoTaken"
        :width="videoWidth"
        :height="videoHeight"
        v-bind:class="{ 'camera-live-invert': frontCamera }"
        ref="camera"
        autoplay
        playsinline
      ></video>

      <v-img
        v-show="isPhotoTaken"
        id="photoTaken"
        ref="canvas"
        :style="{ overflow: 'hidden' }"
        :width="videoWidth * imageDetouredCoefDimension"
        :height="videoHeight * imageDetouredCoefDimension"
        class="my-10"
        contain
      >
        <v-sheet
          v-if="processingInProgress"
          :height="maskHeight"
          class="
            mask
            transparent
            d-flex
            flex-column
            justify-center
            align-center
          "
          :style="{ top: masktop }"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <p class="text-clignote mt-20">Traitement en cours...</p>
        </v-sheet></v-img
      >

      <canvas
        id="realPhotoTaken"
        ref="realCanvas"
        :style="{ display: 'contents' }"
      ></canvas>

      <!-- <v-sheet
        v-if="processingInProgress"
        :height="maskHeight"
        class="mask transparent d-flex flex-column justify-center align-center"
        :style="{ top: masktop }"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <p class="text-clignote mt-40">Traitement en cours...</p>
      </v-sheet> -->
    </div>

    <div class="d-flex justify-space-around">
      <div v-if="isCameraOpen" v-show="!isLoading" class="camera-change">
        <v-btn
          v-show="!isPhotoTaken"
          @click="nextCamera"
          :color="buttonNeutralColor"
          :style="{
            borderRadius: '30px',
            'text-transform': 'none',
            padding: '25px 40px',
            'font-family': 'Georama',
            'font-size': '16px',
            'font-weight': '600',
            'line-height': '20px',
            'letter-spacing': '0em',
            'text-align': 'center',
            marginTop: '5px',
          }"
        >
          <v-icon>mdi-camera-flip-outline</v-icon>
        </v-btn>
      </div>
      <div
        v-if="isCameraOpen && !isLoading && !isPhotoTaken"
        class="camera-shoot"
      >
        <v-btn
          @click="takePhoto"
          :color="color1"
          class="text-wrap"
          :style="{
            color: buttonTextValidColor,
            borderRadius: '30px',
            'text-transform': 'none',
            padding: '25px 70px',
            'font-family': 'Georama',
            'font-size': '16px',
            'font-weight': '600',
            'line-height': '20px',
            'letter-spacing': '0em',
            'text-align': 'center',
            width: '200px',
            marginTop: '5px',
          }"
        >
          <span class="text-center">Prendre la photo</span>
        </v-btn>
      </div>
    </div>
    <div v-if="errorDetected !== ''" style="color: red" class="mt-5">
      <li>
        {{ errorDetected }}
      </li>
    </div>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
import TipsPhoto from '../views/Partner/TipsPhoto.vue'
export default {
  name: 'PhotoShooter',
  components: {
    TipsPhoto,
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      videoWidth: 0,
      videoHeight: 0,
      imageWidth: 0,
      imageHeight: 0,
      videoDevices: [],
      frontCamera: false,
      maskHeight: 0,
      currentIndexCamera: 0,
      selfiMode: true,
      imageCapture: null,
      masktop: '0px',
      processingInProgress: false,
      errorDetected: '',
      imageDetouredCoefDimension: 1,
      rejectReasons: [],
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
    }
  },
  computed: {
    photoReady() {
      return this.isPhotoTaken && this.isCameraOpen
    },
  },
  methods: {
    toggleCamera() {
      this.processingInProgress = false
      this.errorDetected = ''
      if (this.isCameraOpen == false) {
        this.isCameraOpen = true
        this.startCamera()
      } else {
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.isShotPhoto = false
        this.stopCameraStream()
      }
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks()
      tracks.forEach(track => {
        track.stop()
      })
    },
    createCameraElement() {
      // Older browsers might not implement mediaDevices at all, so we set an empty object first
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      // Some browsers partially implement mediaDevices. We can't just assign an object
      // with getUserMedia as it would overwrite existing properties.
      // Here, we will just add the getUserMedia property if it's missing.
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // First get ahold of the legacy getUserMedia, if present
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia
          // Some browsers just don't implement it - return a rejected promise with an error
          // to keep a consistent interface
          if (!getUserMedia) {
            return Promise.reject(
              new Error("La caméra n'est pas disponible sur votre navigateur."),
            )
          }
          // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      this.gotDevices()
      // Listen to the window's size changes
      window.addEventListener('resize', checkForChanges)
      function checkForChanges() {
        //resize canvas when windows dimension change
        this.stopCameraStream()
        this.startCamera()
      }
      window.addEventListener('beforeunload', () => {
        this.stopCameraStream()
      })
      window.addEventListener('orientationchange', () => {
        this.stopCameraStream()
        this.startCamera()
      })
    },
    gotDevices() {
      // List cameras
      const videoDevices = []
      function devices(mediaDevices) {
        mediaDevices.forEach(function (device) {
          if (device.kind == 'videoinput') {
            videoDevices.push(device.deviceId)
          }
        })
      }
      let promiseDevices = navigator.mediaDevices
        .enumerateDevices()
        .then(devices)
      //Result Fetch
      return Promise.all([promiseDevices])
        .then(() => {
          this.videoDevices = videoDevices
        })
        .catch(() => {
          this.$store.dispatch(
            'alert/error',
            "Erreur lors de la demande d'ouverture de la caméra",
          )
        })
    },
    startCamera() {
      this.processingInProgress = false
      this.errorDetected = ''
      if (this.videoDevices.length == 0 || !this.isCameraOpen) {
        return
      }
      this.isLoading = true
      this.videoWidth = 0
      this.videoHeight = 0
      this.maskHeight = 0
      const constraints = (window.constraints = {
        video: {
          width: 3000,
          facingMode: this.selfiMode ? 'user' : 'environment',
          focusMode: 'auto',
        },
      })

      // width: 3000,
      // frameRate: { ideal: 60, max: 120 },
      // aspectRatio: { ideal: 45 / 35 },
      // resizeMode: 'crop-and-scale',
      // zoom: 1,
      // focusMode: 'auto',
      // deviceId: { exact: this.videoDevices[this.currentIndexCamera] },

      this.currentIndexCamera =
        (this.currentIndexCamera + 1) % this.videoDevices.length
      this.selfiMode = !this.selfiMode
      let videoWidth = 0
      let videoHeight = 0
      let imageWidth = 0
      let imageHeight = 0
      let frontCamera = false
      let isLoading = true
      let imageCapture = null
      let promiseUserMedia = navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.$refs.camera.srcObject = stream
          window.stream = stream
          let stream_settings = stream.getVideoTracks()[0].getSettings()
          imageWidth = stream_settings.width
          imageHeight = stream_settings.height
          let widthTmp = stream_settings.width
          let heightTmp = stream_settings.height
          videoWidth = Math.min(413.4, (window.screen.width * 80) / 100)
          videoHeight = (videoWidth * heightTmp) / widthTmp + 100
          //let track = stream.getVideoTracks()[0]
          //imageCapture = new ImageCapture(track)
          frontCamera = stream_settings.facingMode == 'user'
          isLoading = false
        })
      //Result Fetch
      Promise.all([promiseUserMedia])
        .then(() => {
          if (this.videoDevices[this.currentIndexCamera] == '') {
            this.gotDevices()
          }
          this.isLoading = isLoading
          this.videoWidth = videoWidth
          this.videoHeight = videoHeight
          this.imageWidth = imageWidth
          this.imageHeight = imageHeight
          this.frontCamera = frontCamera
          this.imageCapture = imageCapture
          this.maskHeight = Math.max(
            videoHeight / (1.5 + (this.frontCamera ? 0 : 0.3)),
            ((videoWidth / (1.5 + (this.frontCamera ? 0 : 0.5))) * 45) / 35,
          )
          this.masktop = ((this.videoHeight - this.maskHeight) / 5) * 2 + 'px'
        })
        .catch(() => {
          this.$store.dispatch(
            'alert/error',
            "Erreur lors de la demande d'ouverture de la caméra",
          )
        })

      //send video stream result in canvas to show the final result to the user
    },
    //Change camera if possible
    nextCamera() {
      this.stopCameraStream()
      this.startCamera()
    },
    //Take the photo or relaunch the camera
    takePhoto() {
      //start the camera if it's not the case
      if (this.isPhotoTaken) {
        this.stopCameraStream()
        this.startCamera()
        this.isPhotoTaken = !this.isPhotoTaken
        return
      }
      //if (!this.isPhotoTaken) {
      //  this.isShotPhoto = true
      //  const FLASH_TIMEOUT = 50
      //  setTimeout(() => {
      //    this.isShotPhoto = false
      //  }, FLASH_TIMEOUT)
      //}
      this.drawCanvas(
        this.$refs.realCanvas,
        this.$refs.canvas,
        this.$refs.camera,
      )
      this.isPhotoTaken = !this.isPhotoTaken
      this.currentIndexCamera =
        (this.currentIndexCamera - 1 + this.videoDevices.length) %
        this.videoDevices.length
    },
    //Draw image in canvas
    //Draw image in canvas
    drawCanvas(canvas, canvasMini, img) {
      this.imageHeight = (this.imageWidth * img.videoHeight) / img.videoWidth
      canvas.width = this.imageWidth
      canvas.height = this.imageHeight
      canvas.getContext('2d').fillStyle = 'white'
      canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height)
      canvas
        .getContext('2d')
        .drawImage(img, 0, 0, this.imageWidth, this.imageHeight)
      canvasMini.src = canvas.toDataURL() //('image/png')

      //Clone canva for photo resize and detoured
      var cloneCanvas = document
        .getElementById('realPhotoTaken')
        .cloneNode(true)
      cloneCanvas.getContext('2d').fillStyle = 'white'
      cloneCanvas.height = (cloneCanvas.height * 1400) / cloneCanvas.width
      cloneCanvas.width = 1400
      cloneCanvas
        .getContext('2d')
        .fillRect(0, 0, cloneCanvas.width, cloneCanvas.height)
      cloneCanvas
        .getContext('2d')
        .drawImage(img, 0, 0, cloneCanvas.width, cloneCanvas.height)

      //Photo resize
      var canvasUrl = document
        .getElementById('realPhotoTaken')
        .toDataURL('image/jpeg', 1)
      var file = this.dataURLtoBlob(canvasUrl)
      var size = file.size
      var i = 2
      while (size / 1000000 > 2) {
        canvasUrl = document
          .getElementById('realPhotoTaken')
          .toDataURL('image/jpeg', 1 / i)
        file = this.dataURLtoBlob(canvasUrl)
        size = file.size
        i = i + 1
      }

      //Request Photo resize and detoured
      file = this.dataURLtoBlob(cloneCanvas.toDataURL('image/jpeg', 1 / i))
      const photoFormData = new FormData()
      photoFormData.append('file', file, 'image.jpeg')
      photoFormData.append('type', 'photo')
      photoFormData.append('do_apply_detouring', '1')

      this.processingInProgress = true
      this.errorDetected = ''
      var anomalies = undefined
      var imageDetouredCoefDimension = 1
      var rejectReasons = this.rejectReasons
      var errorMessage = ''
      const promsie = requestService
        .post(`/file/resize`, photoFormData, null, null, null, 'arraybuffer')
        .then(function (response) {
          anomalies = response.headers.anomalies
          const image64 = btoa(
            String.fromCharCode(...new Uint8Array(response.data)),
          )
          canvasMini.src = 'data:image/jpeg;base64,' + image64
          imageDetouredCoefDimension = 0.6
        })
      Promise.all([promsie])
        .then(() => {
          //if the photo are rejected by IA, display the error message
          if (anomalies !== undefined) {
            rejectReasons.forEach(ob => {
              var ob1 = Object.entries(ob)
              if (ob1[0][0] == anomalies) {
                errorMessage = ob1[0][1]
              }
            })
          }
          this.processingInProgress = false
        })
        .finally(() => {
          this.processingInProgress = false
          this.imageDetouredCoefDimension = imageDetouredCoefDimension
          this.errorDetected = errorMessage
        })
    },
    //save the result in the stor
    save() {
      var canvasUrl = document
        .getElementById('realPhotoTaken')
        .toDataURL('image/jpeg', 1)
      var file = this.dataURLtoBlob(canvasUrl)
      var size = file.size
      var i = 2
      while (size / 1000000 > 2) {
        canvasUrl = document
          .getElementById('realPhotoTaken')
          .toDataURL('image/jpeg', 1 / i)
        file = this.dataURLtoBlob(canvasUrl)
        size = file.size
        i = i + 1
      }
      localStorageService.setObject('photoUrl', canvasUrl)
    },
    dataURLtoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(',')[1])
      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length)
      // create a view into the buffer
      var ia = new Uint8Array(ab)
      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString })
      return blob
    },
    async loadRejectReason() {
      const formData = new FormData()
      formData.append('app_version', '1')
      await requestService
        .post(`/photo-reject-reason/for/phone/translation`, formData)
        .then(data => {
          this.rejectReasons = data.data
        })
    },
  },
  watch: {
    photoReady(value) {
      this.$emit('photoReady', value)
    },
  },
  beforeDestroy() {
    this.stopCameraStream()
  },
  mounted() {
    this.isCameraOpen = false
    this.videoWidth = 0
    this.videoHeight = 0
    this.videoDevices = []
    this.currentIndexCamera = 0
    this.createCameraElement()
    this.loadRejectReason()
  },
}
</script>

<style scoped lang="scss">
.web-camera-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .camera-button {
    margin-bottom: 1rem;
  }
  .camera-change {
    margin: 1rem;
  }
  .camera-box {
    position: relative;
    .camera-shutter {
      opacity: 0;
      width: 100%;
      height: auto;
      background-color: #fff;
      position: absolute;
      &.flash {
        opacity: 1;
      }
    }
    .mask {
      position: absolute;
    }
  }
  .camera-shoot {
    margin: 1rem;
  }
  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  .text-clignote {
    animation-duration: 1.7s;
    animation-name: clignoter;
    animation-iteration-count: infinite;
    transition: none;
    padding: 5px 50px;
    margin-top: 20px;
    background: #d7d7d74a;
    color: #b30303;
    font-size: larger;
  }
  @keyframes clignoter {
    0% {
      opacity: 0.9;
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 0.9;
    }
  }
}
</style>
Footer © 2023 GitHub, Inc. Footer navigation Terms Privacy Security Status Docs
Contact GitHub Pricing API Training Blog About webapp/PhotoShooter.vue at master
· smartphone-id/webapp
