<template>
  <v-app>
    <v-main>
      <div class="d-flex justify-center">
        <div class="view-container">
          <keep-alive>
            <router-view />
          </keep-alive>
        </div>
      </div>
      <v-snackbar
        :value="alert.active"
        @input="$store.dispatch('alert/updateActive', $event)"
        :color="alert.type"
      >
        {{ alert.message }}
        <v-btn
          dark
          text
          @click.native="$store.dispatch('alert/updateActive', false)"
          >OK
        </v-btn>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    alert() {
      return this.$store.state.alert
    },
  },
}
</script>

<style lang="scss">
.view-container {
  //padding: 2rem;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //border-radius: 4px;
  //width: 1000px;
}
@media (min-width: 500px) {
  .view-container {
    // border: 1px solid #ccc;
    // margin: 2rem 0.5rem;
  }
}
</style>
