/*********************************************** object ***************************************/
/**
 * save the  in the object
 * @param key
 * @param value
 */
function setObject(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

/**
 * return an object
 * @returns ShortliveApiKey
 */
function getValue(key) {
  const val = localStorage.getItem(key)
  if (val === 'null' || val === null || val === 'undefined') {
    return undefined
  }
  return JSON.parse(val)
}

/**
 * Removes all key/value pairs, if there are any.
 * Dispatches a storage event on Window objects holding an equivalent Storage object.
 */
function clearAll() {
  localStorage.clear()
}

/**
 *
 */
function getStyle(hostname, key) {
  const logo = getValue('logo')
  const primaryColor = getValue('primaryColor')
  const secondaryColor = getValue('secondaryColor')
  const color1 = getValue('color1')
  const color2 = getValue('color2')
  const color3 = getValue('color3')
  const color4 = getValue('color4')
  const color5 = getValue('color5')

  const WEBAPPPRO_BACKGROUND_DESKTOP = getValue('WEBAPPPRO_BACKGROUND_DESKTOP')
  const WEBAPPPRO_BACKGROUND_MOBILE = getValue('WEBAPPPRO_BACKGROUND_MOBILE')
  const WEBAPPPRO_LOGO_2 = getValue('WEBAPPPRO_LOGO_2')
  const WEBAPPPRO_ICON_QRCODE_SCREEN = getValue('WEBAPPPRO_ICON_QRCODE_SCREEN')
  const WEBAPPPRO_COLOR_1 = getValue('WEBAPPPRO_COLOR_1')
  const WEBAPPPRO_COLOR_2 = getValue('WEBAPPPRO_COLOR_2')
  const WEBAPPPRO_BUTTON_COLOR_1 = getValue('WEBAPPPRO_BUTTON_COLOR_1')
  const WEBAPPPRO_BUTTON_COLOR_2 = getValue('WEBAPPPRO_BUTTON_COLOR_2')
  const WEBAPPPRO_FONT_FAMILY_1 = getValue('WEBAPPPRO_FONT_FAMILY_1')
  const WEBAPPPRO_FONT_FAMILY_2 = getValue('WEBAPPPRO_FONT_FAMILY_2')
  const WEBAPPPRO_FONT_FAMILY_3 = getValue('WEBAPPPRO_FONT_FAMILY_3')
  const WEBAPPPRO_FONT_SIZE_1 = getValue('WEBAPPPRO_FONT_SIZE_1')
  const WEBAPPPRO_FONT_SIZE_2 = getValue('WEBAPPPRO_FONT_SIZE_2')
  const WEBAPPPRO_FONT_SIZE_3 = getValue('WEBAPPPRO_FONT_SIZE_3')
  const WEBAPPPRO_BUTTON_FONT_SIZE = getValue('WEBAPPPRO_BUTTON_FONT_SIZE')
  const WEBAPPPRO_CARD_BORDER_ROUND = getValue('WEBAPPPRO_CARD_BORDER_ROUND')
  const styles = {
    default: {
      icon:
        logo !== undefined
          ? logo
          : 'https://storage.googleapis.com/smid-static/icon-smartphoneid.png',
      iconWidth: 50,
      iconColor: primaryColor !== undefined ? primaryColor : '#000',
      cardColor: secondaryColor !== undefined ? secondaryColor : '#b2b2b2',
      titleStyle:
        'color:' + (primaryColor !== undefined ? primaryColor : '#000'),
      timelineColor: primaryColor !== undefined ? primaryColor : '#000000',
      buttonValidColor: primaryColor !== undefined ? primaryColor : '#000000',
      buttonTextValidColor: '#ffffff',
      buttonNeutralolor:
        secondaryColor !== undefined ? secondaryColor : '#b2b2b2',
      buttonTextNeutralColor:
        primaryColor !== undefined ? primaryColor : '#000000',
      color1: color1 !== undefined ? color1 : '#2D4490',
      color2: color2 !== undefined ? color2 : '#2D4490',
      color3: color3 !== undefined ? color3 : '#2D4490',
      color4: color4 !== undefined ? color4 : '#000000',
      color5: color5 !== undefined ? color5 : '#E4E4E4',

      WEBAPPPRO_BACKGROUND_DESKTOP:
        WEBAPPPRO_BACKGROUND_DESKTOP !== undefined
          ? WEBAPPPRO_BACKGROUND_DESKTOP
          : 'BG.svg',
      WEBAPPPRO_BACKGROUND_MOBILE:
        WEBAPPPRO_BACKGROUND_MOBILE !== undefined
          ? WEBAPPPRO_BACKGROUND_MOBILE
          : 'BG.svg',
      WEBAPPPRO_LOGO_2: WEBAPPPRO_LOGO_2 !== undefined ? WEBAPPPRO_LOGO_2 : ' ',
      WEBAPPPRO_ICON_QRCODE_SCREEN:
        WEBAPPPRO_ICON_QRCODE_SCREEN !== undefined
          ? WEBAPPPRO_ICON_QRCODE_SCREEN
          : '',
      WEBAPPPRO_COLOR_1:
        WEBAPPPRO_COLOR_1 !== undefined ? WEBAPPPRO_COLOR_1 : '#000000',
      WEBAPPPRO_COLOR_2:
        WEBAPPPRO_COLOR_2 !== undefined ? WEBAPPPRO_COLOR_2 : '#b2b2b2',
      WEBAPPPRO_BUTTON_COLOR_1:
        WEBAPPPRO_BUTTON_COLOR_1 !== undefined
          ? WEBAPPPRO_BUTTON_COLOR_1
          : '#000000',
      WEBAPPPRO_BUTTON_COLOR_2:
        WEBAPPPRO_BUTTON_COLOR_2 !== undefined
          ? WEBAPPPRO_BUTTON_COLOR_2
          : '#b2b2b2',
      WEBAPPPRO_FONT_FAMILY_1:
        WEBAPPPRO_FONT_FAMILY_1 !== undefined
          ? WEBAPPPRO_FONT_FAMILY_1
          : 'ARIAL',
      WEBAPPPRO_FONT_FAMILY_2:
        WEBAPPPRO_FONT_FAMILY_2 !== undefined
          ? WEBAPPPRO_FONT_FAMILY_2
          : 'ARIAL',
      WEBAPPPRO_FONT_FAMILY_3:
        WEBAPPPRO_FONT_FAMILY_3 !== undefined
          ? WEBAPPPRO_FONT_FAMILY_3
          : 'ARIAL',
      WEBAPPPRO_FONT_SIZE_1:
        WEBAPPPRO_FONT_SIZE_1 !== undefined ? WEBAPPPRO_FONT_SIZE_1 : '16',
      WEBAPPPRO_FONT_SIZE_2:
        WEBAPPPRO_FONT_SIZE_2 !== undefined ? WEBAPPPRO_FONT_SIZE_2 : '14',
      WEBAPPPRO_FONT_SIZE_3:
        WEBAPPPRO_FONT_SIZE_3 !== undefined ? WEBAPPPRO_FONT_SIZE_3 : '12',
      WEBAPPPRO_BUTTON_FONT_SIZE:
        WEBAPPPRO_BUTTON_FONT_SIZE !== undefined
          ? WEBAPPPRO_BUTTON_FONT_SIZE
          : '12',
      WEBAPPPRO_CARD_BORDER_ROUND:
        WEBAPPPRO_CARD_BORDER_ROUND !== undefined
          ? WEBAPPPRO_CARD_BORDER_ROUND
          : '5',
    },
    localhost: {
      icon: 'https://storage.googleapis.com/smid-static/icon-smartphoneid.png',
      iconWidth: 50,
      titleStyle: 'color:#000',
      timelineColor: '#c23838',
      buttonValidColor: '#000000',
      buttonTextValidColor: '#ffffff',
      buttonNeutralolor: '#b2b2b2',
      buttonTextNeutralColor: '#000000',
      color1: '#2D4490',
      color2: '#2D4490',
      color3: '#2D4490',
      color4: '#000000',
      color5: '#E4E4E4',

      WEBAPPPRO_BACKGROUND_DESKTOP: 'BG.svg',
      WEBAPPPRO_BACKGROUND_MOBILE: 'BG.svg',
      WEBAPPPRO_LOGO_2: '',
      WEBAPPPRO_ICON_QRCODE_SCREEN: '',
      WEBAPPPRO_COLOR_1: '#000000',
      WEBAPPPRO_COLOR_2: '#b2b2b2',
      WEBAPPPRO_BUTTON_COLOR_1: '#000000',
      WEBAPPPRO_BUTTON_COLOR_2: '#b2b2b2',
      WEBAPPPRO_FONT_FAMILY: 'ARIAL',
      WEBAPPPRO_FONT_SIZE_1: '16',
      WEBAPPPRO_FONT_SIZE_2: '14',
      WEBAPPPRO_FONT_SIZE_3: '12',
      WEBAPPPRO_BUTTON_FONT_SIZE: '12',
      WEBAPPPRO_CARD_BORDER_ROUND: '5',

      iconColor: '#313B82',
      cardColor: '#D6BFF3',
    },
  }
  hostname = !Object.prototype.hasOwnProperty.call(styles, hostname)
    ? 'default'
    : hostname
  if (!Object.prototype.hasOwnProperty.call(styles[hostname], key)) {
    return ''
  }
  return styles[hostname][key]
}

export const localStorageService = {
  getStyle,
  setObject,
  getValue,
  clearAll,
}
