<template>
  <div>
    <photo-shooter
      ref="shooter"
      @photoReady="photoReady = $event"
    ></photo-shooter>
    <div class="d-flex justify-center">
      <!--<v-btn
        v-if="photoReady"
        :color="buttonValidColor"
        :style="'color:' + buttonTextValidColor"
        @click="saveAndContinue"
        :disabled="uploading"
        :loading="uploading"
        >Continuer</v-btn
      >-->
      <v-btn
        v-if="photoReady"
        :disabled="uploading"
        :loading="uploading"
        class="rounded-pill mb-5"
        width="310"
        height="52px"
        @click="saveAndContinue"
        :color="buttonValidColor"
        :style="{
          color: buttonTextValidColor,
          fontWeight: 600,
          fontSize: 16,
          fontFamily: 'Georama',
          textTransform: 'none',
        }"
        >Continuer
      </v-btn>
    </div>
  </div>
</template>

<script>
import PhotoShooter from '@/components/PhotoShooter'
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'

export default {
  name: 'Photo',
  data() {
    return {
      photoReady: false,
      uploading: false,
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
    }
  },
  components: {
    PhotoShooter,
  },
  methods: {
    async saveAndContinue() {
      this.uploading = true
      this.$refs.shooter.stopCameraStream()
      this.$refs.shooter.save()
      if (localStorageService.getValue('rejectStatus') === 'rejected_photo') {
        //Photo save
        const photoFormData = new FormData()
        const photoBlob = await (
          await fetch(localStorageService.getValue('photoUrl'))
        ).blob()
        photoFormData.append('file', photoBlob)
        photoFormData.append('type', 'photo')

        const photoPromise = requestService
          .post(`/file/upload`, photoFormData)
          .then(data => {
            localStorageService.setObject('photoUid', data.data.uid)
          })

        Promise.all([photoPromise])
          .then(() => {
            //order save
            const orderFormData = JSON.stringify({
              status: 'pending_verification',
              photo: {
                status: 'pending_verification',
                newPhotoUid: localStorageService.getValue('photoUid'),
              },
            })
            requestService
              .post(
                '/document/' +
                  localStorageService.getValue('documentId') +
                  '/update',
                orderFormData,
                {},
                {
                  'content-type': 'application/json',
                  shortlive_api_key:
                    localStorageService.getValue('shortliveApiKey'),
                },
              )
              .then(() => {
                this.$router.push({ name: 'Updated' })
              })
              .catch(() => {
                this.$store.dispatch(
                  'alert/error',
                  'Erreur lors de la création de la commande.',
                )
                this.$router.push({ name: 'NotAuthorized' })
              })
          })
          .catch(() => {
            this.$store.dispatch(
              'alert/error',
              "Erreur lors de l'envoi de vos documents.",
            )
            this.$router.push({ name: 'NotAuthorized' })
          })
          .finally(() => {
            this.uploading = false
          })
      } else {
        this.$router.push({ name: 'Signature' })
        this.uploading = false
      }
    },
  },
  mounted() {
    if (localStorageService.getValue('shortliveApiKey') === undefined) {
      this.$router.push({ name: 'NotAuthorized' })
    } else if (localStorageService.getValue('photoUrl') === undefined) {
      this.$router.push({ name: 'ShootPhoto' })
    }
  },
}
</script>
